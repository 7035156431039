<template lang="html">
  <v-container fluid>
    <lock v-if="currentDay != 6 && currentDay != 0" @status="bukaValidasiAktivitas"></lock>
    <template v-if="open || currentDay == 6 || currentDay == 0">
    <v-row>
      <v-col>
        <base-material-card icon="mdi-calendar">
        <v-sheet height="64">
          <v-toolbar
            flat
          >
            <v-btn
              fab
              text
              small
              color="grey darken-2"
              @click="prev"
            >
              <v-icon small>
                mdi-chevron-left
              </v-icon>
            </v-btn>
            <v-btn
              fab
              text
              small
              color="grey darken-2"
              @click="next"
            >
              <v-icon small>
                mdi-chevron-right
              </v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-toolbar-title v-if="$refs.calendar">
              {{ $refs.calendar.title }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
              outlined
              class="mr-4"
              color="grey darken-2"
              @click="setToday"
            >
              Hari ini
            </v-btn>
          </v-toolbar>
        </v-sheet>

        <!-- calendar -->
        <v-sheet height="600">
          <v-calendar
            ref="calendar"
            color="primary"
            type="month"
            v-model="selectedDate"
            @change="updateRange"
            @click:date = "e => onDateClick(e)"
          >
          <template v-slot:day="{date }">
            <v-sheet :class="getConditionalDate(date)" style="margin-top:-45px;" tile >
              <div class="d-flex full-width full-height align-end" style="height:100%">
                <template v-for="(ev, i) in customEvents">
                   <v-tooltip top :key="i">
                     <template v-slot:activator="{ on, attrs }">
                     <v-avatar
                       :key="i"
                       v-if="ev.date == date"
                       :color="ev.color"
                       size="10"
                       class="ml-1 mb-1"
                       v-bind="attrs"
                       v-on="on"
                     />
                   </template>
                   <span>{{ev.name}}</span>
                 </v-tooltip>
                </template>
              </div>
            </v-sheet>
        </template>
        </v-calendar>
        </v-sheet>

        <v-sheet>
          <h3 class="overline">Keterangan</h3>
          <p class="paragraph" style="font-size:12px">
            Klik pada Angka tanggal untuk menuju ke tanggal aktifitas tertentu. <br/>
            <span v-for="(ket,i) in ketLingkaran" :key="i">
              <v-avatar
                :color="ket.color"
                size="10"
                class="mx-2"
              />
              {{ket.text}}
            </span>
          </p>
        </v-sheet>
        </base-material-card>
      </v-col>

      <v-expand-x-transition>
        <div v-show="expand">
          <base-material-card  style="margin-top:40px;" min-width="310" max-width="350" icon="mdi-file-chart">
            <v-tabs
              fixed-tabs
              v-model="tab"
              background-color="primary"
              slider-color="red"
              dark
            >
              <v-tab>
                Utama
                <v-avatar right size="25" class="mx-2 primary lighten-1">
                  {{pegawaiKinerja.total_keseluruhan}}
                </v-avatar>
              </v-tab>
              <v-tab>
                Tambahan
                <v-avatar right size="25" class="mx-2 primary lighten-1">
                  {{year >= 2023 ? pegawaiTambahan.total_keseluruhan_tambahan : pegawaiTambahan.total_keseluruhan}}
                </v-avatar>
              </v-tab>
            </v-tabs>
            <div class="font-weight-medium green--text mt-2 ml-2">{{selectedDate|formatTime}}</div>
            <div v-if="year >= 2023 && tab == 0" class="mt-3">
              <v-row>
                <v-col>
                  <div class="font-weight-medium green--text ml-2">Total Aktivitas: {{pegawaiKinerja.total_keseluruhan}}</div>
                </v-col>
                <v-col>
                  <div class="font-weight-medium red--text ml-2">Belum Divalidasi: {{belum_divalidasi}}</div>
                </v-col>
              </v-row>
            </div>
            <div v-if="loadingCard">
              <v-card width="300" outlined v-for="i in range(2)" :key="i">
                <v-skeleton-loader
                type="list-item-avatar-three-line,  actions"
                ></v-skeleton-loader>
              </v-card>
            </div>
            <div v-else-if="filtered && filtered.length > 0">
              <v-card
              class="mx-auto"
              width="300"
              v-for="(p,i) in filtered" :key="i"
              outlined
              >
                <div class="d-flex flex-no-wrap">
                  <v-avatar
                  class="ma-3"
                  size="60"
                  >
                    <v-img :src="p.foto"></v-img>
                  </v-avatar>

                  <div>
                    <v-card-text class="mb-1 pb-1">
                      <div class="orange--text text--darken-2" style="font-size:1em;text-overflow:ellipsis;overflow:hidden;white-space:no-wrap">{{p.nama}}</div>
                      <div class="grey--text" style="font-size:.8em;">{{p.nip}}</div>
                      <div class="green--text text--darken-2" style="font-size:.8em">{{p.jabatan}}</div>
                    </v-card-text>
                    <v-card-actions class="mt-1 pt-1">
                      <v-chip
                      class="ma-2 elevation-1"
                      color="blue"
                      text-color="white"
                      @click="getAktivitasTampil(p)"
                      >
                        <v-avatar
                        left
                        class="blue darken-4"
                        >
                        <!-- {{p.data_aktivitas.length}} -->
                        {{ tab == 0 ? p.belum_valid : p.belum_valid_tambahan }}
                        </v-avatar>
                        Aktivitas
                      </v-chip>
                    </v-card-actions>

                    <v-card-actions class="mt-0 pt-0">
                      <v-chip
                      class="ma-2 elevation-1"
                      color="green"
                      text-color="white"
                      >
                        <v-avatar
                        left
                        class="green darken-4"
                        >
                        {{ tab == 0 ? p.valid : p.valid_tambahan }}
                        </v-avatar>
                        Sudah Divalidasi
                      </v-chip>
                    </v-card-actions>
                  </div>
                </div>
              </v-card>
            </div>
            <div v-else>
              <v-card
              class="mx-auto"
              width="300"
              outlined
              color="blue"
              dark
              >
                <v-card-text style="color:white">
                  <v-icon>mdi-information</v-icon> Tidak ada aktivitas
                </v-card-text>
              </v-card>
            </div>
          </base-material-card>
        </div>
      </v-expand-x-transition>
    </v-row>

    <v-dialog v-model="dialog">
      <v-card>
        <validasi-detail @update="update" :dataAktivitas="dialogData" :pegawai="dialogPegawai" :tanggal="selectedDate"></validasi-detail>
        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- <v-btn @click="refresh()">Tutup</v-btn> -->
          <v-btn @click="dialog=false">Tutup</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    </template>
  </v-container>
</template>

<script>
import _ from 'lodash'
import Lock from '@/components/common/Lock'
import moment from 'moment'
import ValidasiDetail from './ValidasiDetail'
import PenilaianService from '@/services/PenilaianService'
import store from '@/store'

export default {
  components:{
    ValidasiDetail,
    Lock,
  },
  data: () => ({
    tab: 0,
    batasTanggal: '',
    conditionalDate: {},
    expand: false,
    customEvents: {},
    selectedDate: '',
    cari: '',
    aktivitas: {},
    pegawaiKinerja: [],
    pegawaiTambahan: [],
    loadingCard:false,
    dialogData:{},
    dialogPegawai:{},
    dialog:false,
    startDate:'',
    endDate:'',

    ketLingkaran: [{
        'text': 'Jumlah disetujui',
        color: 'success'
      },
      {
        'text': 'Jumlah ditolak',
        color: 'error'
      },
      {
        'text':'Jumlah aktivitas utama masih diproses', 
        color:'info'
      },
      {
        'text':'Jumlah aktivitas tambahan masih diproses', 
        color:'brown'
      },
      // {
      //   'text': 'Penunjukan aktivitas kerja',
      //   color: 'warning'
      // },
      // {
      //   'text': 'Penunjukan target kerja',
      //   color: 'purple'
      // },
    ],
    open:false,
    year:null,
    belum_divalidasi:0,
    selected: null
  }),

  filters: {
    addZero: function(value) {
      if (value * 1 < 10) {
        return '0' + value
      }
      return value
    },
    formatTime: function(value){
      moment.locale('id')
      return moment(value).format('dddd, Do MMMM YYYY');
    }
  },

  computed: {
    currentDay(){
      return moment().day()
    },
    filteredData() {
      if (this.tab == 0) {
        return this.filteredAU
      }
      return this.filteredTmb
    },

    filtered(){
      if(this.tab === 0){
        return this.pegawaiKinerja.data
      }else {
        return this.pegawaiTambahan.data
      }
    },

    showTab() {
      let date = this.selectedDate
      return this.compareDate(date, null) == -1 && this.compareDate(date, this.batasTanggal) == 1 || this.compareDate(date, this.batasTanggal) == 0 || this.compareDate(date, null) == 0
    }
  },

  created() {
    let user = store.state.user
    this.year = user.current.year
    const startOfMonth = moment().startOf('month').format('YYYY-MM-DD')
    const endOfMonth = moment().endOf('month').format('YYYY-MM-DD')

    this.getCalendar(startOfMonth, endOfMonth)
  },

  mounted() {
    // this.$refs.calendar.checkChange()
  },

  methods: {
    bukaValidasiAktivitas(val){
      console.log(val, this.open)
      if (val == 'open'){
        this.open = true
      } else {
        this.open = false
      }
    },
    range(i){
      return _.range(i)
    },

    update(){
      this.updateCalendar()
    },

    getCalendar(startDate, endDate) {
      this.customEvents = []

      PenilaianService.getValidasiCalendar({
        from: startDate,
        to: endDate
      }).then(response => {
        // let events = []
        let customEvents = []
        let rawEvents = response.data.data

        for (let i = 0; i < rawEvents.length; i++) {
          let color = rawEvents[i].class.replace('event-', '')

          switch (color) {
            case 'important':
              color = "red"
              break;
            case 'special':
              color = "purple lighten-2"
              break;
          }
          customEvents.push({
            name: rawEvents[i].title,
            count: rawEvents[i].title.match(/\d+/) ? rawEvents[i].title.match(/\d+/)[0] : 0,
            date: rawEvents[i].tanggal,
            color: color
          })

        }
        this.customEvents = customEvents
      })
    },

    getAktivitasTampil(data) {
      let user = store.state.user
      if(user.current.year >= 2023){
        if(this.tab === 0){
          this.dialogData = data.data_aktivitas_utama
        }else{
          this.dialogData = data.data_aktivitas_tambahan
        }
      }else{
        this.dialogData = data.data_aktivitas
      }
      this.dialogPegawai = {nama:data.nama,foto:data.foto, nip:data.nip}
      this.dialog=true
    },

    onDateClick(e) {
      this.selected = e.date
      this.pegawaiKinerja.total_keseluruhan = 0
      this.belum_divalidasi = 0
      let idx = this.customEvents.findIndex(x => x.date === e.date)
      if (idx > -1) {
        this.loadingCard = true
        this.expand = true

        this.pegawaiKinerja = []
        PenilaianService.getValidasiTampil(e.date, 'kinerja').then(response => {
          this.pegawaiKinerja = response.data
          let user = store.state.user
          if(user.current.year >= 2023){
            this.pegawaiTambahan = response.data
            response.data.data.forEach(e => {
              this.belum_divalidasi += e.belum_valid
            });
          }
          setTimeout(()=>{
            this.loadingCard = false
          },500)
        })

        let user = store.state.user
        if(user.current.year < 2023){
          this.pegawaiTambahan = []
          PenilaianService.getValidasiTampil(e.date, 'umum').then(response => {
            this.pegawaiTambahan = response.data
            setTimeout(()=>{
              this.loadingCard = false
            },500)
          })
        }
      } else {
        this.expand = false
      }
    },

    compareDate(a, b) {
      let dateA = new Date(a)
      let dateB = b ? new Date(b) : new Date()
      dateA.setHours(0, 0, 0, 0)
      dateB.setHours(0, 0, 0, 0)
      if (dateA.getTime() > dateB.getTime()) {
        return 1
      } else if (dateA.getTime() < dateB.getTime()) {
        return -1
      }
      return 0
    },

    getConditionalDate(date) {
      let allowed = this.compareDate(date, null) == -1 && this.compareDate(date, this.batasTanggal) == 1 || this.compareDate(date, this.batasTanggal) == 0 || this.compareDate(date, null) == 0

      let idx = this.customEvents.findIndex(x => this.compareDate(x.date, date) == 0)
      let hasEvent = idx >= 0 ? true : false

      let today = this.compareDate(date, this.selectedDate) == 0

      return {
        "green lighten-3": hasEvent && allowed,
        "amber lighten-3": hasEvent && !allowed,
        'blue lighten-4': allowed,
        "orange lighten-1": today,
        'fill-height': true,
        'fill-width': true
      }
    },

    updateCalendar(){
      this.getCalendar(this.startDate, this.endDate)
    },

    updateRange({
      start,
      end
    }) {
      this.startDate = start.date
      this.endDate = end.date
      this.getCalendar(start.date, end.date)
    },

    setToday() {
      this.selectedDate = ''
    },

    prev() {
      this.$refs.calendar.prev()
    },

    next() {
      this.$refs.calendar.next()
    },

    refresh(){
      this.dialog=false
      this.pegawaiKinerja.total_keseluruhan = 0
      this.belum_divalidasi = 0
      this.loadingCard = true
      this.expand = true
      this.pegawaiKinerja = []
      PenilaianService.getValidasiTampil(this.selected, 'kinerja').then(response => {
        this.pegawaiKinerja = response.data
        let user = store.state.user
        if(user.current.year >= 2023){
          this.pegawaiTambahan = response.data
          response.data.data.forEach(e => {
            this.belum_divalidasi += e.belum_valid
          });
        }
        setTimeout(()=>{
          this.loadingCard = false
        },500)
      })
      let user = store.state.user
      if(user.current.year < 2023){
        this.pegawaiTambahan = []
        PenilaianService.getValidasiTampil(this.selected, 'umum').then(response => {
          this.pegawaiTambahan = response.data
          setTimeout(()=>{
            this.loadingCard = false
          },500)
        })
      }
    }

  },
}
</script>

<style lang="scss">
</style>
